/**
 * defaultParams - объект с дефолтными параметрами
 * unicStorageName - строка с для именования в localStorage
 * matchesChar - строка c символом по которому происходит замена/добавление параметров в ссылке (не обязательный параметр)
 * selector - строка с селектором. По умолчанию '[data-query]'
 * */

/**
 * Работает с ссылками вида example.com, example.com?foo-bar, example.com#foo
 * */

/**
 * (дополнительные символы '?' в ссылках не нужны)
 * */


export let queryParams;
export let queryParamsLink = '';

export function addQueryParams(defaultParams, unicStorageName, matchesChar, selector = '[data-query]', requiredParams = 'ref') {
  const searchParams = (new URL(document.location)).searchParams;
  const getLocalStorageParams = localStorage.getItem(unicStorageName);
  queryParams = defaultParams;

  const setQueryParamsLink = (iterable = null) => {

    if (iterable) {
      queryParams = Object.assign(queryParams, Object.fromEntries(iterable));
    }

    queryParamsLink = new URLSearchParams(queryParams).toString()
  }

  if ([...searchParams].length) {

    if (searchParams.has(requiredParams) && searchParams.get(requiredParams) === '' || !searchParams.has(requiredParams)) {
      searchParams.set(requiredParams, `${defaultParams[requiredParams]}`)
    }

    setQueryParamsLink(searchParams)
    localStorage.setItem(unicStorageName, JSON.stringify(Object.fromEntries(searchParams)))
  } else if (getLocalStorageParams) {
    setQueryParamsLink(Object.entries(JSON.parse(getLocalStorageParams)))
  } else {
    setQueryParamsLink()
  }

  document.querySelectorAll(selector).forEach(el => {
    const str = el.href;
    const presetParams = str.indexOf(matchesChar) > 0 ? str.slice(str.indexOf(matchesChar)) : null;

    if (presetParams && matchesChar) {
      switch (matchesChar) {
        case '?':
          el.href = `${el.href.split(matchesChar)[0]}${presetParams}&${queryParamsLink}`;
          break;
        case '#':
          el.href = `${el.href.split(matchesChar)[0]}?${queryParamsLink}${presetParams}`;
          break;
      }
    } else {
      el.href = `${el.href}?${queryParamsLink}`;
    }

  });
}
